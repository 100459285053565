import { Button } from '@chakra-ui/react'
import { Affix, Card } from 'antd'
import { useApp } from 'lodestar-app-element/src/contexts/AppContext'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// custom part
import CountDownTimeBlock from '../../../../components/common/CountDownTimeBlock'
import { AvatarImage } from '../../../../components/common/Image'
// custom part
import PriceLabel from '../../../../components/common/PriceLabel'
import Responsive from '../../../../components/common/Responsive'
import ProgramGroupBuyingInfo from '../../../../customize/ProgramGroupBuyingInfo'
import { commonMessages } from '../../../../helpers/translation'
import { usePublicMember } from '../../../../hooks/member'
import { useEquityProgramByProgramId } from '../../../../hooks/program'
import { Category } from '../../../../types/general'
import { Program, ProgramContent, ProgramContentSection, ProgramPlan, ProgramRole } from '../../../../types/program'
// custom part
import ProgramPlanPaymentButton from '../ProgramPlanCard/ProgramPlanPaymentButton'
import ProgramContentCountBlock from './ProgramContentCountBlock'

const ProgramInfoCard = styled(Card)`
  && {
    margin-bottom: 2.5rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }
  .ant-card-body {
    padding: 1rem;
  }
`
const StyledInstructorName = styled.div`
  margin-bottom: 28px;
  color: #585858;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`

const StyledCountDownBlock = styled.div`
  margin-top: 15px;
  span {
    font-size: 14px;
  }
`

const ProgramInfoBlock: React.VFC<{
  instructorId: string
  program: Program & {
    categories: Category[]
    roles: ProgramRole[]
    plans: (ProgramPlan & {
      isSubscription: boolean
      groupBuyingPeople: number
    })[]
    contentSections: (ProgramContentSection & {
      contents: ProgramContent[]
    })[]
  }
}> = ({ instructorId, program }) => {
  const { formatMessage } = useIntl()
  const { member } = usePublicMember(instructorId)
  const { isEquityProgram } = useEquityProgramByProgramId(program.id)
  const { enabledModules } = useApp()

  const publishedPlans = program.plans.filter(v => v.publishedAt)
  const isOnSale = (publishedPlans[0].soldAt?.getTime() || 0) > Date.now()
  const isOnlyPerpetualPlan = publishedPlans.length === 1 && publishedPlans[0].periodType === null

  return (
    <>
      <Responsive.Default>
        <ProgramInfoCard>
          <ProgramContentCountBlock program={program} />
        </ProgramInfoCard>
      </Responsive.Default>

      <Responsive.Desktop>
        <Affix offsetTop={40} target={() => document.getElementById('layout-content')}>
          <ProgramInfoCard>
            {member && (
              <>
                <Link to={`/creators/${member.id}?tabkey=introduction`}>
                  <AvatarImage src={member.pictureUrl || ''} size={96} className="my-3 mx-auto" />
                </Link>
                <Link to={`/creators/${member.id}?tabkey=introduction`}>
                  <StyledInstructorName>{member.name}</StyledInstructorName>
                </Link>
              </>
            )}

            <ProgramContentCountBlock program={program} />

            <div className="text-center mb-3">
              {isOnlyPerpetualPlan && (
                <PriceLabel
                  variant="inline"
                  listPrice={publishedPlans[0]?.listPrice || 0}
                  salePrice={isOnSale ? publishedPlans[0]?.salePrice || 0 : undefined}
                />
              )}
              {program.isCountdownTimerVisible && publishedPlans[0]?.soldAt && isOnSale && (
                <StyledCountDownBlock>
                  <CountDownTimeBlock expiredAt={publishedPlans[0]?.soldAt} />
                </StyledCountDownBlock>
              )}
            </div>

            {isEquityProgram && isOnlyPerpetualPlan ? (
              <Link to={`/programs/${program.id}/contents`}>
                <Button className="cat-active bg-primary" variant="outline" colorScheme="primary" isFullWidth>
                  {formatMessage(commonMessages.button.enter)}
                </Button>
              </Link>
            ) : enabledModules.group_buying && publishedPlans.some(v => v.groupBuyingPeople > 1) ? (
              <ProgramGroupBuyingInfo isOnSale={isOnSale} program={program} programPlans={publishedPlans} />
            ) : (
              isOnlyPerpetualPlan && <ProgramPlanPaymentButton programPlan={publishedPlans[0]} />
            )}
          </ProgramInfoCard>
        </Affix>
      </Responsive.Desktop>
    </>
  )
}

export default ProgramInfoBlock
